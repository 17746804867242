@import 'styles/config';

.root {
  max-width: $panel-width;
  padding: 0 0.5rem;
  display: grid;
  grid-gap: 0.3rem;
}

.item {
  border-radius: 0.8rem;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: bold;
  color: $color-primary;
  text-align: center;
  justify-content: center;
  padding: 0.6rem 0.1rem;
  &:global {
    &.Mui-selected {
      background-color: rgba($color-primary, 0.05);
      opacity: 1;
    }
  }
}
