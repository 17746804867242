@import 'styles/config';

@mixin scrollbar {
  &::-webkit-scrollbar {
    background-color: $color-grey-light;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-grey-light;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-grey;
    &:hover {
      background-color: $color-grey;
    }
  }
}

.root {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: transparent;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.3rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
    background-color: transparent;
  }

  &.all,
  &:hover {
    @include scrollbar;
  }
}
