@import 'styles/config';

.root {
  width: $panel-width;
  transition: width 0.25s ease-in-out;
  &Open {
    width: $panel-width-open;
  }
}

.paper {
  width: $panel-width;
  transition: width 0.25s ease-in-out;
  background-color: $color-grey-light;
  border-right: 1px solid $color-grey-lines;
  box-shadow: none;
  &Open {
    width: $panel-width-open;
    border-right-color: transparent;
    background-color: $color-grey-light;
  }
  &::before {
    display: block;
    content: '';
    position: absolute;
    left: $panel-width;
    top: $panel-width;
    bottom: 0;
    right: 0;
    background-color: #fff;
    border-radius: $border-radius;
  }
}
.inner {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  height: 100%;
  overflow: hidden;
  & > * {
    min-width: 0;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .paper {
    &::before {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
}
