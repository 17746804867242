$box-shadow-dark-blue: 0 0.4rem 2.4rem 0 rgba(#0d334b, 0.1);

$button-shadow: 0 0.8rem 0.8rem 0 rgba(#000, 0.15);

$box-shadow: 0 0 0.5rem 0 rgba(#000, 0.15);

$panel-width: 6.4rem;
$header-height: 6.4rem;
$panel-width-open: 32rem;

$transition: 0.2s;

$border-radius: 1.6rem;
$border-radius-box: 1.2rem;
