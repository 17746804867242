@import 'styles/config';

.item {
  padding: 0 2rem 0 0;

  &.itemInner {
    margin-left: 6.4rem;
    min-height: 3.2rem;
    &:last-child {
      margin-bottom: 0.8rem;
    }
    .icon {
      display: none;
    }
  }

  &:hover {
    background: none;
    .icon {
      color: $color-primary;
      &:after {
        opacity: 0.05;
      }
    }
  }
  &.itemActive {
    .icon {
      color: #fff;
      &:after {
        height: 5.2rem;
        width: 5.2rem;
        opacity: 1;
      }
    }
    .text {
      color: $color-primary;
      font-weight: bold;
    }
  }
  &.itemMenuOpen {
    &.itemActive {
      .icon {
        color: $color-primary;
        &:after {
          opacity: 0;
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.icon {
  height: 4.8rem;
  width: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: rgba($color-grey-dark, 0.5);
  transition: $transition;
  position: relative;
  z-index: 1;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0.6rem;
    transform: translateY(-50%);
    height: 7.2rem;
    width: 5.8rem;
    background: url('../../../../images/bg-nav-active.svg') repeat;
    opacity: 0;
    z-index: -1;
    transition: $transition;
  }
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3.6rem;
    width: 3.6rem;
    background-color: $color-primary;
    border-radius: 0.8rem;
    z-index: -1;
    opacity: 0;
    transition: $transition opacity;
  }
  &Rtl {
    &::before {
      transform: translateY(-50%) scaleX(-1);
    }
  }
}
.text {
  padding: 0 0 0 3rem;
  font-weight: 500;
}

.arrow {
  font-size: 2rem;
  color: rgba($color-primary, 0.3);
}
