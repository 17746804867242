@import 'styles/config';

$height-title: 5.6rem;

.root {
  height: $header-height;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 0.8rem;
  padding: 0.5rem 1.6rem;
}

.title {
  overflow: hidden;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    height: auto;
    grid-template-columns: 1fr;
    padding-top: 0;
    padding-bottom: 0;
  }
  .wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0 1.6rem;
    background-color: #fff;
    box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .title {
    margin-top: $header-height;
    align-items: center;
    display: grid;
    height: $height-title;
  }
}
