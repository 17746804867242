@import 'styles/config';

.root {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  text-transform: none;
  grid-gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem;
  text-align: right;
  max-width: 26rem;
  > * {
    min-width: 0;
  }
}

.avatar {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.icon {
  transition: transform ease-in 0.3s;
  transform-origin: center;
  &Open {
    transform: scaleY(-1);
  }
}

.title {
  color: $color-grey;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
}

//@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
//  .root {
//    grid-template-columns: 1fr;
//    grid-gap: 0;
//    padding: 0 0.6rem;
//    margin: 0 -0.6rem;
//  }
//  .icon {
//    display: none;
//  }
//}
