@import 'styles/config';

.root {
  display: grid;
  grid-template-columns: 23rem 1fr;
  height: 100%;
  border-radius: 0;
  border-top: 1px solid $color-grey-lines;
  background: #fff;
  overflow: hidden;
  & > * {
    min-width: 0;
  }
}

.panel {
  background-color: #f8f8f8;
  padding: 0.7rem;
  position: relative;
}

.wrap {
  padding: 1.3rem 1.6rem 0;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    border: none;
    background-color: transparent;
  }
  .wrap {
    padding: 1rem 0 0;
  }
}

.tabs {
  min-height: inherit;
  &Indicator {
    display: none;
  }
  &FlexContainer {
    gap: 0.4rem;
  }
}
.tab {
  text-align: left;
  align-items: flex-start;
  padding: 0.4rem 1rem;
  min-height: 3.6rem;
  color: $color-grey-dark;
  border-radius: 0.8rem;
  &Selected {
    background-color: $color-primary;
    color: #fff !important;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .tab {
    align-items: center;
    text-align: center;
  }
}
