$color-primary: #062a74;
$color-primary-light: rgba($color-primary, 0.6);

$color-grey: #c0c0c0;
$color-grey-dark: #393939;
$color-grey-light: #f8f8f8;
$color-grey-lines: #eeeeee;

$color-form-icon: rgba(#393939, 0.3);
$color-form-label: rgba(#393939, 0.5);

$color-red: #d8404c;
$color-green: #51b849;
$color-blue: #006cf2;
$color-orange: #f49511;

$color-chart-start: $color-primary-light;
$color-chart-end: $color-primary;

//===========================//

$color-grey-icon: #c5cfd3;
$color-grey-light-2: #f5f6f7;

$color-secondary: #009f19;
$color-secondary-dark: #008214;

$color-light-blue: #f2f9ff;
$color-grey-old: #c0c0c0;
