@import 'styles/config';
.root {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2.4rem;
  flex-grow: 1;
  & > * {
    min-width: 0;
  }
}

.content {
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 1rem 1rem 1rem 0;
  border-radius: $border-radius;
  box-shadow: 0 0.4rem 3.2rem rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-template-columns: 1fr;
  }
  .content {
    border-radius: 0;
    margin: 0 !important;
  }
}
