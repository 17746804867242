@import 'styles/config';
.root {
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-width: $panel-width;
  min-height: $header-height;
}

.button {
  height: 6.4rem;
  width: 6.4rem;
  color: rgba($color-grey-dark, 0.5);
  transition: $transition;
  position: relative;
}
