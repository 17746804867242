@import 'styles/config';

html {
  font-size: 10px;
  min-height: calc(var(--vh, 1vh) * 100 - 0.01px);
  height: 100%;
  overflow: hidden;
}

body {
  overflow: auto;
}
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}

body {
  color: $color-grey-dark;
  font-size: 1.4rem;
  font-family: Arial, Tahoma, Geneva, sans-serif;
}
.container {
  width: 100%;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-right: auto;
  margin-left: auto;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}

#root {
  min-height: 100%;
  height: auto;
}

@media (min-width: map-get($breakpoints, 'lg') + px) {
  body {
    &::-webkit-scrollbar {
      width: 0.6rem;
      height: 0.6rem;
      background-color: $color-grey-light;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-grey-light;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.3rem;
      background-color: $color-grey;
    }
  }
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
